var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-row',{staticClass:"mt-12 ml-4"},[_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#007bff"}},[_vm._v(" Employee Name ")]),_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#6c757d"}},[_vm._v(" Employee Designation ")]),_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#dc3545"}},[_vm._v(" Employee Department ")])],1),_c('v-row',{staticClass:"ml-4"},[(_vm.selectedCompanyUserStatus != 'employee')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.departmentLists,"item-text":"name","label":"Search By Department"},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1):_vm._e(),(_vm.selectedCompanyUserStatus != 'employee')?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.projectListAll,"item-text":"project_name","label":"Select a Project"},model:{value:(_vm.project_name),callback:function ($$v) {_vm.project_name=$$v},expression:"project_name"}})],1):_vm._e(),_c('v-col',{staticClass:"d-grid d-sm-flex float-right",attrs:{"cols":"12","sm":"6","md":"6","lg":"2"}},[(_vm.Keys && _vm.onlineEmployee.length > 0)?_c('RecursiveMonitoring2',{attrs:{"recursiveEmployees":this.membersToMonitor}}):_vm._e(),_c('div',{staticClass:"my-2 mt-3 ml-1"},[_c('v-btn',{staticClass:"allrefresh",on:{"click":function($event){return _vm.forceRerender()}}},[_vm._v(" Refresh ")])],1)],1)],1),_c('v-row',{staticClass:"ml-4 mx-2"},[_vm._l((_vm.onlineEmployee),function(employee){return (
        Object.keys(_vm.onlineEmployee).length !== 0 &&
        employee.image_status == true
      )?_c('v-col',{key:employee.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto my-12"},[(_vm.renderComponent)?_c('MultipleRealTime',{attrs:{"userIdEmployee":employee.id,"userName":employee.name,"getStatus":_vm.memberStatus(employee.id)}}):_vm._e(),_c('v-card-title',[_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#007bff"}},[_vm._v(" "+_vm._s(employee.name)+" ")]),(employee.designation)?_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#6c757d"}},[_vm._v(" "+_vm._s(employee.designation)+" ")]):_vm._e(),(employee.department_name)?_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#dc3545"}},[_vm._v(" "+_vm._s(employee.department_name)+" ")]):_vm._e()],1)],1)],1):_vm._e()}),_vm._l((_vm.onlineEmployee),function(employee){return (
        Object.keys(_vm.onlineEmployee).length !== 0 &&
        employee.image_status == false
      )?_c('v-col',{key:employee.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"mx-auto my-12"},[(_vm.renderComponent)?_c('MultipleRealTime',{attrs:{"userIdEmployee":employee.id,"userName":employee.name,"getStatus":_vm.memberStatus(employee.id)}}):_vm._e(),_c('v-card-title',[_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#007bff"}},[_vm._v(" "+_vm._s(employee.name)+" ")]),(employee.designation)?_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#6c757d"}},[_vm._v(" "+_vm._s(employee.designation)+" ")]):_vm._e(),(employee.department_name)?_c('v-chip',{staticClass:"ma-2 white--text",attrs:{"small":"","label":"","color":"#dc3545"}},[_vm._v(" "+_vm._s(employee.department_name)+" ")]):_vm._e()],1)],1)],1):_vm._e()}),(Object.keys(_vm.onlineEmployee).length === 0)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('img',{staticClass:"error-img",attrs:{"src":require("@/assets/images/no-activity-found-new.svg")}}),_c('p',{staticClass:"error_text"},[_vm._v("No data found")])]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }