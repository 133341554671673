<template>
  <v-app>
    <!-- Employee lables-->
    <v-row class="mt-12 ml-4">
      <v-chip small class="ma-2 white--text" label color="#007bff">
        Employee Name
      </v-chip>
      <v-chip small class="ma-2 white--text" label color="#6c757d">
        Employee Designation
      </v-chip>
      <v-chip small class="ma-2 white--text" label color="#dc3545">
        Employee Department
      </v-chip>
    </v-row>
    <v-row class="ml-4">
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
        v-if="selectedCompanyUserStatus != 'employee'"
      >
        <v-autocomplete
          v-model="department"
          :items="departmentLists"
          item-text="name"
          label="Search By Department"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        lg="2"
        v-if="selectedCompanyUserStatus != 'employee'"
      >
        <v-autocomplete
          v-model="project_name"
          :items="projectListAll"
          item-text="project_name"
          label="Select a Project"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        class="d-grid d-sm-flex float-right"
        lg="2"
      >
        <RecursiveMonitoring2
          v-if="Keys && onlineEmployee.length > 0"
          :recursiveEmployees="this.membersToMonitor"
        />
        <div class="my-2 mt-3 ml-1">
          <v-btn class="allrefresh" @click="forceRerender()"> Refresh </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="ml-4 mx-2">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="employee in onlineEmployee"
        :key="employee.id"
        v-if="
          Object.keys(onlineEmployee).length !== 0 &&
          employee.image_status == true
        "
      >
        <v-card class="mx-auto my-12">
          <MultipleRealTime
            :userIdEmployee="employee.id"
            :userName="employee.name"
            :getStatus="memberStatus(employee.id)"
            v-if="renderComponent"
          ></MultipleRealTime>
          <v-card-title>
            <v-chip small class="ma-2 white--text" label color="#007bff">
              {{ employee.name }}
            </v-chip>
            <v-chip
              small
              class="ma-2 white--text"
              label
              color="#6c757d"
              v-if="employee.designation"
            >
              {{ employee.designation }}
            </v-chip>
            <v-chip
              small
              class="ma-2 white--text"
              label
              color="#dc3545"
              v-if="employee.department_name"
            >
              {{ employee.department_name }}
            </v-chip>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="employee in onlineEmployee"
        :key="employee.id"
        v-if="
          Object.keys(onlineEmployee).length !== 0 &&
          employee.image_status == false
        "
      >
        <v-card class="mx-auto my-12">
          <MultipleRealTime
            :userIdEmployee="employee.id"
            :userName="employee.name"
            :getStatus="memberStatus(employee.id)"
            v-if="renderComponent"
          ></MultipleRealTime>
          <v-card-title>
            <v-chip small class="ma-2 white--text" label color="#007bff">
              {{ employee.name }}
            </v-chip>
            <v-chip
              small
              class="ma-2 white--text"
              label
              color="#6c757d"
              v-if="employee.designation"
            >
              {{ employee.designation }}
            </v-chip>
            <v-chip
              small
              class="ma-2 white--text"
              label
              color="#dc3545"
              v-if="employee.department_name"
            >
              {{ employee.department_name }}
            </v-chip>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="12"
        v-if="Object.keys(onlineEmployee).length === 0"
      >
        <img
          class="error-img"
          src="@/assets/images/no-activity-found-new.svg"
        />
        <p class="error_text">No data found</p>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { sha256 } from "js-sha256";
import { mapState } from "vuex";
export default {
  name: "RealTimeMonitor",
  components: {
    // select2: () => import("@/components/assets/select2"),
    MultipleRealTime: () =>
      import("@/components/TeamMembers/RealTimeMonitor/MultipleRealTime"),
    RecursiveMonitoring2: () =>
      import("@/components/TeamMembers/RealTimeMonitor/RecursiveMonitoring2"),
  },
  data() {
    return {
      membersToMonitor: [],
      department: "All",
      project_name: "All",
      departmentList: [],
      renderComponent: true,
      search: null,
      project_list: [],
      dialog2: false,
      getAllKeys: [],
      checkFilterDepartment: false,
      checkFilterProject: false,
    };
  },
  watch: {
    department: function () {
      if (this.checkFilterDepartment == true) {
        this.filterByOnlineMembers();
      }
      this.checkFilterDepartment = true;
    },
    project_name: function () {
      this.filterByOnlineMembers();
    },

  },
  computed: {
    ...mapState("custom", ["companies_list"]),
    departmentLists() {
      let arrayDep = [];
      let allTeamMembers = {
        name: "All",
      };
      arrayDep.push(allTeamMembers);
      this.departmentList.map((department) => {
        let newDepartment = {
          hash_id: department.hash_id,
          name: department.name,
        };
        arrayDep.push(newDepartment);
      });
      return arrayDep;
    },
    projectListAll() {
      let arrayPro = [];
      let allproject = {
        project_name: "All",
      };
      arrayPro.push(allproject);
      this.project_list.map((project) => {
        let newProject = {
          id: project.id,
          project_name: project.project_name,
        };
        arrayPro.push(newProject);
      });
      return arrayPro;
    },

    onlineEmployee() {
      let groupMontring = this.$store.state.custom.groupMontring;
      return groupMontring;
    },
    keyData() {
      if (this.$route.params.id) {
        return sha256(this.$route.params.id);
      }
    },
    Keys() {
      this.getAllKeys = this.$store.state.pf.keysData;
      if (this.getAllKeys.includes(this.keyData)) {
        return true;
      }
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    checkForEmployees() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) {
        if (company.user_status == "employee") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    this.onlineMembers();
    this.projectMembers();
    if (
      !this.$store.state.custom.departments ||
      this.$store.state.custom.departments.length == 0
    ) {
      this.get_department_list();
    } else {
      this.departmentList = this.$store.state.custom.departments;
    }
    const company = this.$store.state.custom.companies_list.find(
      (c) => c.id === this.$route.params.id
    );
    if (company) {
      if (company.user_status == "employee") {
        if (company.has_team < 1) {
          this.$router.push({ name: "Dashboard" });
        }
      }
    }
    let selected_filter_key = this.$store.state.custom.members_filter_key;
    if (
      selected_filter_key.search_by_lead != "ALL" ||
      selected_filter_key.search_by_name != "ALL"
    ) {
      (this.project_name =
        selected_filter_key.project != "all"
          ? selected_filter_key.project
          : ""),
        (this.department =
          selected_filter_key.department != "all"
            ? selected_filter_key.department
            : "");
    } else {
      if (selected_filter_key.project != null) {
        this.project_name = selected_filter_key.project;
      }
      if (selected_filter_key.department != null) {
        this.department = selected_filter_key.department;
      }
    }
  },
  methods: {
    filterByOnlineMembers() {
      this.activeTeamMembersList();
    },
    activeTeamMembersList() {
      let filterOnlineMembers;
      let Onlineemployees = [];
      let currentMembers = [];
      Onlineemployees = this.$store.state.custom.all_store_socket_employee;
      currentMembers = this.$store.state.custom.active_team_members_list;
      if (
        this.department &&
        this.project_name != "" &&
        this.department !== "All"
      ) {
        currentMembers = currentMembers.filter(
          (member) => member.department_name == this.department
        );
      }
      let groupEmployee = [];
      Onlineemployees.map((data) => {
        groupEmployee.push(data.user_id);
      });
      filterOnlineMembers = currentMembers.filter((memberOnline) =>
        groupEmployee.includes(memberOnline.id)
      );
      filterOnlineMembers = filterOnlineMembers.map((m) => ({
        ...m,
        image_status: false,
      }));
      filterOnlineMembers.find((fom) => {
        Onlineemployees.find((om) => {
          if (fom.id == om.user_id) fom.project_name = om.project;
        });
      });
      if (
        this.project_name &&
        this.project_name != "" &&
        this.project_name !== "All"
      ) {
        filterOnlineMembers = filterOnlineMembers.filter(
          (members) => members.project_name === this.project_name
        );
      }
      let selectedFilterKey = {
        project: this.project_name == "" ? "All" : this.project_name,
        department: this.department,
        search_by_name: null,
        search_by_lead: null,
      };
      this.$store.commit("custom/set_member_filter_key", selectedFilterKey);
      this.$store.commit("custom/group_montring", filterOnlineMembers);
    },
    projectMembers() {
      let active_members = this.$store.state.custom.team_member_with_project;
      if (active_members.length == 0) {
        let data = this.$store
          .dispatch("custom/get_team_members", {
            company_id: this.$route.params.id,
          })
          .then((response) => {
            active_members = response.data.team_members;
          });
      }
      let projects = [];
      this.$nextTick(() =>
        active_members.map((member) => {
          if (member.id != null) {
            member.projects.map((project) => {
              const found = projects.some((el) => el.id === project.id);
              if (!found) projects.push(project);
            });
          }
        })
      );
      this.project_list = projects;
    },
    memberStatus(id) {
      let employees_online = this.$store.state.custom.all_store_socket_employee.find(
        (data) => data.user_id === id
      );
      if (employees_online) return employees_online.task_running;
    },
    filterOnlineEmployee() {
      let onlineemployees, groupEmployee, filterOnlineMembers, currentMembers;
      onlineemployees = this.$store.state.custom.all_store_socket_employee;
      groupEmployee = [];
      onlineemployees.map((data) => {
        groupEmployee.push(data.user_id);
      });
      currentMembers = this.$store.state.custom.groupMontring;
      filterOnlineMembers = currentMembers.filter((memberOnline) =>
        groupEmployee.includes(memberOnline.id)
      );
      console.log(filterOnlineMembers, "commit 3");
      this.$store.commit("custom/group_montring", filterOnlineMembers);
    },
    onlineMembers() {
      let onlineMembers;
      onlineMembers = this.$store.state.custom.groupMontring;
      onlineMembers.map((data) => {
        this.membersToMonitor.push(data);
      });
    },
    forceRerender() {
      this.filterOnlineEmployee();
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
      const filterOnlineMembers = this.$store.state.custom.groupMontring.map(
        (member) => {
          var temp = Object.assign({}, member);
          temp.image_status = false;
          return temp;
        }
      );
      this.$store.commit("custom/group_montring", filterOnlineMembers);
    },

    get_department_list() {
      let data = {
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/getDeaprtmentList", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.departmentList = response.data.department;
          this.$store.commit("custom/set_departments", this.departmentList);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response) {
            if (error.response.data.message === "Unauthenticated.") {
              this.$store.commit("custom/resetState");
              this.$store.dispatch("auth/reset").then(() => {
                return this.$router.push({ name: "login" });
              });
            }
          }
        });
    },
  },
};
</script>
<style scoped>
.allrefresh {
  color: white !important;
  background-color: #2758f6 !important;
}
.error-img {
  max-width: 20% !important;
}
.custom-chip {
  width: 64% !important;
}
.v-badge__badge {
  font-size: 14px !important;
  border-right: 0px !important;
}
</style>
